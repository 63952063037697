<template>
  <div>
    <!-- Drawer抽屉 -->
    <el-dialog  :close-on-click-modal="false" append-to-body :title="title"	 :visible.sync="visible" width="476px" >
      <div :style="{maxHeight: maxHeight}" class="drawer-content">
        <Form ref="form" :model="form" :rules="formValidate"  label-position="top">
          <Row :gutter="16">
            <Col span="24">
              <FormItem label="车牌号" prop="licensePlate" >
                <el-input style="width: 100%;" placeholder="车牌号" v-model="form.licensePlate" clearable/>
              </FormItem>
            </Col>
            <Col span="24">
              <FormItem label="物料名称" prop="goodsName" >
                <el-select filterable  style="width: 100%;" placeholder="物料名称" v-model="form.goodsName">
                  <el-option v-for="item in itemArray" :value="item.goodsName">{{item.goodsName}}</el-option>
                </el-select>
              </FormItem>
            </Col>
          
          </Row>
        </Form>
      </div>
      <div class="drawer-footer br" style="text-align:right" slot="footer" v-show="type!='0'">
        <el-button  type="primary" :loading="submitLoading" @click="submit">提交</el-button>
        <el-button @click="visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 根据你的实际请求api.js位置路径修改
import { saveLicensePlate, updateLicensePlate,getCompanyGoodNames } from "@/api/index";
export default {
  name: "addEdit",
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    type: {
      type: String,
      default: "0"
    },
    company:{
      type: Object,
      default: {}
    },
    companyUser:{
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      visible: this.value,
      title: "",
      submitLoading: false,
      maxHeight: 510,
      itemArray:[],
      form: { // 添加或编辑表单对象初始化数据
        goodsName: "",
        licensePlate: "",
        compId:this.company.id
      },
      // 表单验证规则
      formValidate: {
        licensePlate: [{required: true, message: "请输入" }],
        goodsName: [{ required: true, message: "请输入"}],
    }
    };
  },
  methods: {
    init() {
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.type == "1") {
            // 编辑
            this.submitLoading = true;
            updateLicensePlate(this.form).then(res => {
              this.submitLoading = false;
              if (res.success) {
                this.$Message.success("操作成功");
                this.$emit("on-submit", true);
                this.visible = false;
              }
            });
          } else {
            // 添加
            this.submitLoading = true;
            saveLicensePlate(this.form).then(res => {
              this.submitLoading = false;
              if (res.success) {
                this.$Message.success("操作成功");
                this.$emit("on-submit", true);
                this.visible = false;
              }
            });
          }
        }
      });
    },
    setCurrentValue(value) {
      if (value === this.visible) {
        return;
      }
      if (this.type == "1") {
        this.title = "编辑";
        this.maxHeight =
          Number(document.documentElement.clientHeight - 121) + "px";
      } else if (this.type == "2") {
        this.title = "添加";
        this.maxHeight =
          Number(document.documentElement.clientHeight - 121) + "px";
      } else {
        this.title = "详细信息";
        this.maxHeight = "100%";
      }
      // 清空数据
      //this.$refs.form.resetFields();
      if (this.type == "0" || this.type == "1") {
        // 回显数据处理
        this.form = this.data;
      } else {
        // 添加
        this.form = {
          licensePlate: "",
          goodsName: "",
          compId:this.company.id
        }
        //delete this.form.id;
      }
      this.visible = value;
    }
  },
  watch: {
    value(val) {
      // alert(val)
      this.setCurrentValue(val);
    },
    visible(value) {
      this.$emit("input", value);
    }
  },
  mounted() {
    this.init();

    getCompanyGoodNames(this.company.id).then(res=>{
      let result = res.result 
      this.itemArray = result 
    })

  }
};
</script>

<style lang="less">
@import "@/styles/drawer-common.less";
</style>